.login{
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 200px;
  .login-form{
    max-width: 300px;
    margin: 0 auto;
    text-align: left;
    .login-form-button{
      width: 100%;
    }
    .captcha{
      margin-bottom: 0;
      .img{
        width: 150px;
        position: relative;
        top:-10px;
        cursor: pointer;
      }
    }
  }
  
}