.App {
  margin: 0;
  padding: 0;
  min-height: 100%;
}
.header{
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  padding: 0;
  height: 60px;
}
.userinfo{
  margin-right: 10px;
}
.ant-layout{
  flex-grow: 1;
}
.ant-layout-sider{
  background-color: #fff !important;
}
.footer{
  text-align: center;
  color: #fff;
  background: #7dbcea;
}
.ant-tag{
  margin: 1px;
}

/* @import url("//at.alicdn.com/t/c/font_4291953_qa9rxdah1up.css") */
@import url("//at.alicdn.com/t/c/font_4291953_cu0dhh1a4lo.css")
